import AContactList from './index'
import AContactForm from './form'

export default [
  {
    path: '/contacts/',
    component: AContactList,
    name: 'contacts',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/contacts/create',
    component: AContactForm,
    name: 'contacts_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contacts/:contact/update',
    component: AContactForm,
    name: 'contacts_update',
    meta: {
      requiresAuth: true
    }
  },
];
