import ADriverList from './index'
import ADriverForm from './form'

export default [
  {
    path: '/drivers/',
    component: ADriverList,
    name: 'drivers',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/drivers/create',
    component: ADriverForm,
    name: 'drivers_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/drivers/:driver/update',
    component: ADriverForm,
    name: 'drivers_update',
    meta: {
      requiresAuth: true
    }
  },
];
