<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Contato</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn disabled icon @click="$router.push({ name: 'contacts_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="contacts">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchContacts()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="headers"
                    :items="contacts.data"
                    :page.sync="contacts.meta.current_page"
                    :items-per-page.sync="contacts.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.read="{ item }">
            <span class="green--text" v-if="item.read">Lido</span>
            <span class="red--text" v-else>Não Lido</span>
          </template>
          <template v-slot:item.answered="{ item }">
            <span class="green--text" v-if="item.answered">Respondido</span>
            <span class="red--text" v-else>Não Respondido</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'contacts_update', params: { contact: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn :href="`https://api.whatsapp.com/send?phone=55${item.telephone}`" target="_blank" :disabled="!item.telephone" icon class="mr-0">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn @click="destroyContact(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="contacts.meta.last_page" :total-visible="7" @input="fetchContacts()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-contact-list',
    data: () => ({
      contacts: null,
      page: 1,
      search: '',
      headers: [
        { text: 'Status', value: 'read' },
        { text: 'Respondido', value: 'answered' },
        { text: 'Assunto', value: 'subject' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Telefone', value: 'telephone' },
        { text: 'Ação', align: 'center', width: 180, value: 'action' },
      ],
    }),
    created() {
      this.fetchContacts();
    },
    methods: {
      async fetchContacts() {
        const contacts = await this.$axios.get(`/managers/${this.manager.id}/contacts`, { params: { name: this.search, page: this.page } })
        this.contacts = contacts.data;
      },
      async destroyContact(contact) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/managers/${this.manager.id}/contacts/${contact.id}`)
          await this.fetchContacts();
        }
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
