<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Dados de Segurança</h1>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="payload.email" label="Email*" type="email"
                              :error-messages="formErrors.email"
                              hide-details="auto" :rules="[$rules.required, $rules.email]"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="payload.password" label="Senha*" type="password"
                              :error-messages="formErrors.password"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="payload.password_confirmation" label="Nova Senha*" type="password"
                              :error-messages="formErrors.password_confirmation"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'a-profile-security-update',
    data: () => ({
      formErrors: {},
      payload: null,
      valid: true,
    }),
    created() {
      this.$store.dispatch('fetchProfile', {
        manager: this.manager.id
      }).then((response) => {
        this.payload = {
          email: response.data.data.email,
          password: '',
          password_confirmation: '',
        }
      });
    },
    methods: {
      updateProfile() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateProfile', {
          manager: this.manager.id,
          payload: this.payload
        }).then((response) => {
          this.$refs.form.reset();
          this.$store.dispatch('loginSetBusiness', response.data.data);
          this.$router.back();
        }).catch((error) => {
          if(error.response && error.response.status === 422){
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
