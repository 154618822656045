import resource from '../../resource';

const state = {
  countries: null,
};

const getters = {
  getCountries(state) {
    return state.countries;
  },
};

const mutations = {
  SET_COUNTRIES(state, data) {
    state.countries = data;
  },
};

const actions = {
  fetchCountries(context) {
    return resource.get(`/countries`)
      .then((response) => {
        context.commit('SET_COUNTRIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchCountry(context, { country }) {
    return resource.get(`/countries/${country}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchCountries(context, { search }) {
    return resource.get(`/countries`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_COUNTRIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toCountriesPage(context, { search, page }) {
    return resource.get(`/countries`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_COUNTRIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createCountry(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/countries`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateCountry(context, { manager, country, payload }) {
    return resource.put(`/managers/${manager}/countries/${country}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteCountry(context, { manager, country }) {
    return resource.delete(`/managers/${manager}/countries/${country}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
