<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Categoria</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'categories_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="categories">
      <v-col cols="12">
        <v-text-field v-model="categoriesSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchCategories()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="categoriesHeaders"
                    :items="categories.data"
                    :page.sync="categories.meta.current_page"
                    :items-per-page.sync="categories.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.banner="{ item }">
            <v-img class="align-end rounded" width="48" :src="item.banner ? item.banner.url : require('../../assets/placeholder.png')" aspect-ratio="1">
              <svg class="d-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 159">
                <path :fill="item.color" d="M1000,92v67H0v-6a44.29,44.29,0,0,0,4.43.44q67.25.13,134.49.2c11.19,0,14.46-4.25,11.43-15.25h4.47q184.24.14,368.47.26,140.49.09,281,.11c1.83,0,4.06.37,5.38-.52,2-1.33,4.63-3.66,4.56-5.47-.09-2.15-2.38-4.82-4.43-6.09-1.74-1.07-4.52-.62-6.83-.62q-203-.06-406-.08-132.5,0-265-.08-66,0-132,.1V91c1.5-.07,3-.21,4.49-.21l305.85.34,431.79.53Q871.06,91.82,1000,92Z"/>
                <path :fill="item.color" d="M322,0c2.41.39,4.81,1.11,7.22,1.12q120.21.09,240.43.07h3c3.35,0,7.35-.49,7.41,4.37s-4,4.37-7.33,4.37l-230.44,0h-6.92c.33,1.64.51,2.86.83,4,3.74,13.47-4.77,25.62-18.79,26.09-12.15.41-24.32.08-36.49.08L0,40V0Z"/>
                <path :fill="item.color" d="M1000,72c-11.65-.1-23.31-.3-35-.31q-387.6-.48-775.19-.91-91.71-.12-183.42-.22c-2.14,0-4.29.29-6.43.44V66q65.46.1,130.93.21,151.68.15,303.36.26,279.36.34,558.73.73c2.33,0,4.65-.13,7-.2Z"/>
                <path :fill="item.color" d="M1000,60c-2.16-.07-4.32-.2-6.48-.2H839.71a31.23,31.23,0,0,1-5.48-.2,8.48,8.48,0,0,1-7-9,8.23,8.23,0,0,1,8-7.48c1.66-.12,3.33,0,5,0L1000,43Z"/>
                <path :fill="item.color" d="M597,32q39.21,0,78.42,0c5.87,0,8.89,2.17,9.62,6.6,1,5.87-2.7,10-9.31,10.11-12.49.13-25,0-37.47,0l-116.38,0c-1.5,0-3,.06-4.49,0-5.6-.33-9.18-3.71-9.14-8.56s3.54-8.14,9.33-8.16c26.47-.07,52.94,0,79.42,0Z"/>
              </svg>
              <span :style="`background-color: ${item.color}; font-size: 8px`" class="d-block px-1 text-truncate white--text text-center text-uppercase">
                {{ item.name }}
              </span>
            </v-img>
          </template>
          <template v-slot:item.order="{ item }">
            {{ item.order }}º
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'categories_update', params: { category: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyCategory(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="categories.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-category-list',
    data: () => ({
      page: 1,
      categoriesSearch: '',
      categoriesHeaders: [
        { text: 'Banner', align: 'center', width: 48, value: 'banner' },
        { text: 'Nome', value: 'name' },
        { text: 'Ordem', value: 'order' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toCategoriesPage', {
          search: this.categoriesSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchCategories();
    },
    methods: {
      fetchCategories() {
        this.$store.dispatch('fetchCategories');
      },
      searchCategories() {
        this.$store.dispatch('searchCategories', {
          search: this.categoriesSearch
        });
      },
      destroyCategory(category) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteCategory', {
            manager: this.manager.id,
            category: category.id
          }).then(() => {
            this.fetchCategories();
          });
        }
      },
    },
    computed: {
      categories() {
        return this.$store.getters.getCategories;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
