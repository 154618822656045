<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Garçom</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Garçom</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-switch inset v-model="payload.blocked" label="Bloquear" hide-details="auto"/>
                </v-col>
                <v-col cols="12" v-if="payload.blocked">
                  <v-select v-model="payload.blocked_reason" :items="selectBlockedReasons" label="Motivo do bloqueio*"
                            :error-messages="formErrors.blocked_reason"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeWaiter()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateWaiter()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-waiter-form',
    data: () => ({
      formErrors: {},
      payload: {
        blocked: '',
        blocked_reason: '',
      },
      selectBlockedReasons: [
        { text: 'Linguágem Inapropriada', value: 'INAPPROPRIATE_LANGUAGE' },
        { text: 'Conteúdo Falso ou Spam', value: 'FAKE_CONTENT' },
        { text: 'Fraude', value: 'SCAM' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchWaiter', {
          waiter: this.$route.params.waiter
        }).then((response) => {
          this.payload = {
            blocked: response.data.data.blocked,
            blocked_reason: response.data.data.blocked_reason,
          }
        });
      }
    },
    methods: {
      storeWaiter() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createWaiter', {
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateWaiter() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateWaiter', {
          waiter: this.$route.params.waiter,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'waiters_create';
      },
      updating() {
       return this.$route.name === 'waiters_update';
      },
    },
  };
</script>
