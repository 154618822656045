<template>
  <v-row align-content="center" justify="center" class="fill-height">
    <v-col class="text-xs-center" cols="12" sm="8" md="6">
      <v-img :src="require('../../assets/404.svg')"/>
      <h6 class="text-h6 font-weight-light pt-2 text-center">Página não encontrada</h6>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-http-not-found'
};
</script>
