import APlanList from './index'
import APlanForm from './form'

export default [
  {
    path: '/plans/',
    component: APlanList,
    name: 'plans',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/plans/create',
    component: APlanForm,
    name: 'plans_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/plans/:plan/update',
    component: APlanForm,
    name: 'plans_update',
    meta: {
      requiresAuth: true
    }
  },
];
