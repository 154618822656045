<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Contato</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Contato</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  Nome: {{ payload.name }}
                </v-col>
                <v-col cols="12">
                  Email: <a target="_blank" :href="`mailto:${payload.email}?subject=Resposta - ${payload.subject}`">{{ payload.email }}</a>
                </v-col>
                <v-col cols="12">
                  Telefone: <a target="_blank" :href="`https://api.whatsapp.com/send?phone=55${payload.telephone}`">{{ payload.telephone }}</a>
                </v-col>
                <v-col cols="12" class="pt-4">
                  {{ payload.subject }}
                </v-col>
                <v-col cols="12" class="pt-4">
                  {{ payload.message }}
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.answered" label="Marcar Como Respondido"
                            :error-messages="formErrors.answered"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeContact()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateContact()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-contact-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        email: '',
        telephone: '',
        subject: '',
        message: '',
        read: '',
        answered: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchContact();
      }
    },
    methods: {
      async fetchContact() {
        const contact = await this.$axios.get(`/managers/${this.manager.id}/contacts/${this.$route.params.contact}`)
        this.payload = {
          name: contact.data.data.name,
          email: contact.data.data.email,
          telephone: contact.data.data.telephone,
          subject: contact.data.data.subject,
          message: contact.data.data.message,
          read: contact.data.data.read,
          answered: contact.data.data.answered,
        }
      },
      async storeContact() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/managers/${this.manager.id}/contacts`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateContact() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/contacts/${this.$route.params.contact}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'contacts_create';
      },
      updating() {
       return this.$route.name === 'contacts_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
