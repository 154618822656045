import resource from "../../resource";
const tokenKeyName = 'PUB_MANAGER_TOKEN';
const managerKeyName = 'PUB_MANAGER_MANAGER';

const state = {
  token: null,
  manager: null
};

const getters = {
  getLoginToken(state) {
    if (localStorage.getItem(tokenKeyName)){
      state.token = localStorage.getItem(tokenKeyName);
    }
    return state.token;
  },
  getLoginAuthManager(state) {
    if (localStorage.getItem(managerKeyName)){
      state.manager = JSON.parse(localStorage.getItem(managerKeyName));
    }
    return state.manager;
  },
  getLoginAuthCheck(state) {
    return !!state.token || !!localStorage.getItem(tokenKeyName);
  },
};

const mutations = {
  SET_LOGIN_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(tokenKeyName, token);
  },
  REMOVE_LOGIN_TOKEN(state) {
    state.token = null;
    localStorage.removeItem(tokenKeyName);
  },
  SET_LOGIN_MANAGER(state, manager) {
    state.manager = manager;
    localStorage.setItem(managerKeyName, JSON.stringify(manager));
  },
  REMOVE_LOGIN_MANAGER(state) {
    state.manager = null;
    localStorage.removeItem(managerKeyName);
  },
};

const actions = {
  loginCreateSession(context, credentials) {
    return resource.post('/auth/manager/login', credentials)
      .then((response) => {
        context.commit('SET_LOGIN_TOKEN', response.data.access_token);
        return context.dispatch('loginFetchAuthManager');
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveManager');
        return Promise.reject(error);
      });
  },
  loginRemoveSession(context) {
    return resource.post('/auth/manager/logout')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveManager');
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveManager');
        return Promise.reject(error);
      });
  },
  loginRemoveSessions(context) {
    return resource.post('/auth/manager/revoke')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveManager');
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  loginFetchAuthManager(context) {
    return resource.get('/auth/manager/me')
      .then((response) => {
        context.dispatch('loginSetManager', response.data.data);
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveSession');
        return Promise.reject(error);
      });
  },
  loginSetToken(context, data) {
    context.commit('SET_LOGIN_TOKEN', data);
  },
  loginRemoveToken(context) {
    context.commit('REMOVE_LOGIN_TOKEN');
  },
  loginRemoveManager(context) {
    context.commit('REMOVE_LOGIN_MANAGER');
  },
  loginSetManager(context, data) {
    context.commit('SET_LOGIN_MANAGER', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
