<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Suporte</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'managers_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="managers">
      <v-col cols="12">
        <v-text-field v-model="managersSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchManagers()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="managersHeaders"
                    :items="managers.data"
                    :page.sync="managers.meta.current_page"
                    :items-per-page.sync="managers.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.avatar="{ item }">
            <v-avatar rounded width="48" height="48">
              <v-img :src="item.avatar ? item.avatar.url : require('../../assets/profile.png')" aspect-ratio="1"/>
            </v-avatar>
          </template>
          <template v-slot:item.birth_date="{ item }">
            {{ DateTime.fromSQL(item.birth_date).setLocale('pt-BR').toFormat('dd LLL yyyy') }}
          </template>
          <template v-slot:item.sex="{ item }">
            {{ userSex(item.sex) }}
          </template>
          <template v-slot:item.role="{ item }">
            {{ userRole(item.role) }}
          </template>
          <template v-slot:item.blocked="{ item }">
            <v-simple-checkbox v-model="item.blocked" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'managers_update', params: { manager: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyManager(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn disabled icon class="mr-0">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="managers.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-manager-list',
    data: () => ({
      page: 1,
      managersSearch: '',
      managersHeaders: [
        { text: 'Avatar', align: 'center', width: 48, value: 'avatar' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Data de Nascimento', value: 'birth_date' },
        { text: 'Sexo', value: 'sex' },
        { text: 'Função', value: 'role' },
        { text: 'Bloqueado', value: 'blocked' },
        { text: 'Ação', align: 'center', width: 180, value: 'action' },
      ],
      DateTime: DateTime,
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toManagersPage', {
          search: this.managersSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchManagers();
    },
    methods: {
      fetchManagers() {
        this.$store.dispatch('fetchManagers');
      },
      searchManagers() {
        this.$store.dispatch('searchManagers', {
          search: this.managersSearch
        });
      },
      destroyManager(manager) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteManager', {
            manager: manager.id
          }).then(() => {
            this.fetchManagers();
          });
        }
      },
      userSex(sex) {
        switch (sex) {
          case 'MALE':
            return 'Masculino';
          case 'FEMALE':
            return 'Feminino';
          default:
            return 'Não definido';
        }
      },
      userRole(sex) {
        switch (sex) {
          case 'DEVELOPER':
            return 'Desenvolvedor';
          case 'SUPPORT':
            return 'Suporte';
          case 'FINANCIAL':
            return 'Financeiro';
          case 'MEDIA':
            return 'Comunicação';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      managers() {
        return this.$store.getters.getManagers;
      },
    },
  };
</script>
