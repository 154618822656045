import AProfile from './index';
import AProfileAboutUpdate from "../../components/Profile/AProfileAboutUpdate";
import AProfileSecurityUpdate from "../../components/Profile/AProfileSecurityUpdate";

export default [
  {
    path: '/profile',
    name: 'profile',
    component: AProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/about',
    component: AProfileAboutUpdate,
    name: 'profile_about',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/security',
    component: AProfileSecurityUpdate,
    name: 'profile_security',
    meta: {
      requiresAuth: true
    }
  }
];

