import ASpotList from './index'
import ASpotForm from './form'

export default [
  {
    path: '/spots/',
    component: ASpotList,
    name: 'spots',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/spots/create',
    component: ASpotForm,
    name: 'spots_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/spots/:spot/update',
    component: ASpotForm,
    name: 'spots_update',
    meta: {
      requiresAuth: true
    }
  },
];
