import ACityList from './index'
import ACityForm from './form'

export default [
  {
    path: '/cities/',
    component: ACityList,
    name: 'cities',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/cities/create',
    component: ACityForm,
    name: 'cities_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cities/:city/update',
    component: ACityForm,
    name: 'cities_update',
    meta: {
      requiresAuth: true
    }
  },
];
