<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Arquivo</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Arquivo</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" v-if="creating">
                  <v-file-input v-model="payload.file" label="Arquivo*"
                                :error-messages="formErrors.file"
                                hide-details="auto" :rules="[$rules.required]">
                    <slot name="progress">
                      <v-progress-linear :value="upload"/>
                    </slot>
                  </v-file-input>
                </v-col>
                <v-col cols="12" v-if="updating">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeFile()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateFile()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-file-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        file: null
      },
      upload: 0,
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchFile();
      }
    },
    methods: {
      async fetchFile() {
        const file = await this.$axios.get(`/managers/${this.manager.id}/files/${this.$route.params.file}`)
        this.payload = {
          name: file.data.data.name,
        }
      },
      async storeFile() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          const formData = new FormData();
          formData.append('file', this.payload.file);

          await this.$axios.post(`/managers/${this.manager.id}/files`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (event) => { this.upload = Math.round((event.loaded * 100) / event.total) },
          });
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateFile() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/files/${this.$route.params.file}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'files_create';
      },
      updating() {
       return this.$route.name === 'files_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
