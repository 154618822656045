<template>
  <v-app>
    <!--Navigation-->
    <v-navigation-drawer v-if="authCheck" v-model="drawer" clipped fixed app>
      <template v-slot:prepend>
        <v-list-item two-line v-if="manager">
          <v-list-item-avatar rounded>
            <v-img :src="manager.avatar ? manager.avatar.url : require('./assets/profile.png')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ manager.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ roles(manager.role) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider/>

      <v-list nav dense>
        <v-list-item :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'banks' }">
          <v-list-item-icon>
            <v-icon>mdi-bank</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bancos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'banners' }">
          <v-list-item-icon>
            <v-icon>mdi-image</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Banners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'files' }">
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Arquivos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'categories' }">
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Categorias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'cities' }">
          <v-list-item-icon>
            <v-icon>mdi-city</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cidades</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'states' }">
          <v-list-item-icon>
            <v-icon>mdi-routes</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Estados</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'countries' }">
          <v-list-item-icon>
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Países</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'payment_methods' }">
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Métodos de Pagamento</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'plans' }">
          <v-list-item-icon>
            <v-icon>mdi-brightness-percent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Planos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'posts' }">
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Postagens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'faqs' }">
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>FAQs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'contacts' }">
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contatos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'spots' }">
          <v-list-item-icon>
            <v-icon>mdi-card-bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Spots</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'businesses' }">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Estabelecimentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'drivers' }">
          <v-list-item-icon>
            <v-icon>mdi-motorbike</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Motoristas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'waiters' }">
          <v-list-item-icon>
            <v-icon>mdi-room-service</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Garçons</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'managers' }">
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Suporte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'policies' }">
          <v-list-item-icon>
            <v-icon>mdi-security</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Políticas de Privacidade</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'terms' }">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Termos de Uso</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'cookies' }">
          <v-list-item-icon>
            <v-icon>mdi-cookie</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cookies</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'settings' }">
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuração</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-row class="pa-3" dense>
          <v-col>
            <v-btn depressed block @click="logout">Sair</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <!--App Bar-->
    <v-app-bar v-if="authCheck" dark app fixed clipped-left color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title class="headline text-uppercase">
        <router-link :to="{ name: 'dashboard', params: { status: 'PENDING' } }">
          <span class="white--text">POINT</span>
          <span class="white--text font-weight-light">URBANO</span>
          <span class="white--text body-2">{{ appName }}</span>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <!--Content-->
    <v-main :class="{ 'login-back': routesTheme.includes($route.name) }">
      <v-container fluid class="fill-height">
        <router-view/>
      </v-container>
    </v-main>
    <!--Loading Dialog-->
    <v-overlay :value="loadingDialog" opacity="0">
      <v-card color="primary" class="pa-1" width="65">
        <v-img :src="require('./assets/loading.svg')" contain/>
      </v-card>
    </v-overlay>
    <!--Show Snackbar-->
    <v-snackbar v-model="showSnackbar" :timeout="10000" top right dark color="primary">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>

export default {
  name: 'Home',
  data: () => ({
    routesTheme: ['login', 'register', 'reset_password'],
    firebaseInitialized: false
  }),
  watch: {
    themeDark(value) {
      this.$vuetify.theme.dark = value;
    },
  },
  created() {
    this.$vuetify.theme.dark = this.themeDark;
    if (this.authCheck) {
      this.$store.dispatch('loginFetchAuthManager');
    }
  },
  methods: {
    roles(role){
      switch (role) {
        case 'DEVELOPER':
          return 'Desenvolvedor';
        case 'SUPPORT':
          return 'Suporte';
        case 'FINANCIAL':
          return 'Financeiro';
        case 'MEDIA':
          return 'Mídia';
      }
    },
    logout() {
      if (confirm('Tem certeza que deseja sair?')) {
        this.$store.dispatch('loginRemoveSession')
          .finally(() => {
            this.$store.dispatch('setDrawerStatus', null);
            this.$router.push({ name: 'login' });
          });
      }
    }
  },
  computed: {
    appName() {
      return this.$store.getters.getAppName;
    },
    themeDark() {
      return this.$store.getters.getThemeDark;
    },
    drawer: {
      get: function () {
        return this.$store.getters.getDrawerStatus;
      },
      set: function (status) {
        this.$store.dispatch('setDrawerStatus', status);
      }
    },
    loadingDialog: {
      get: function () {
        return this.$store.getters.getLoadingDialog;
      },
      set: function (status) {
        this.$store.dispatch('setLoadingDialogStatus', status);
      }
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.getShowSnackbar;
      },
      set: function (status) {
        this.$store.dispatch('setShowSnackbarStatus', status);
      }
    },
    snackbarText() {
      return this.$store.getters.getSnackbarText;
    },
    manager() {
      return this.$store.getters.getLoginAuthManager;
    },
    authCheck() {
      return this.$store.getters.getLoginAuthCheck;
    },
  }
}
</script>
