<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Spot</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Spot</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.order" label="Ordem*" type="number"
                                :error-messages="formErrors.order"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="payload.type" :items="selectTypes" label="Tipo*"
                            :error-messages="formErrors.type"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="payload.params" label="Parâmetros*" type="text"
                                :error-messages="formErrors.params"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.time_start" label="Hora Início*" type="time" step="1"
                                :error-messages="formErrors.time_start"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.time_end" label="Hora Fim*" type="time" step="1"
                                :error-messages="formErrors.time_end"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea auto-grow rows="1" v-model="payload.description" label="Descrição*"
                              :error-messages="formErrors.description"
                              hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.active" label="Ativo"
                            :error-messages="formErrors.active"
                            hide-details="auto"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Imagem da categoria</h1>
                </v-col>
                <v-col cols="12">
                  <a-banner-search v-model="payload.banner_id"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeSpot()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateSpot()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import ABannerSearch from "@/components/ABannerSearch";
  export default {
    name: 'a-spot-form',
    components: {ABannerSearch},
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        type: 'BUSINESS',
        order: 1,
        params: '{"category":1}',
        description: '',
        time_start: '00:00',
        time_end: '23:59',
        active: false,
      },
      selectTypes: [
        { text: 'Estabelecimentos', value: 'BUSINESS' },
        { text: 'Itens', value: 'ITEM' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchSpot', {
          spot: this.$route.params.spot
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            type: response.data.data.type,
            order: response.data.data.order,
            params: response.data.data.params ? JSON.stringify(response.data.data.params) : '',
            description: response.data.data.description,
            time_start: response.data.data.time_start,
            time_end: response.data.data.time_end,
            active: response.data.data.active,
            banner_id: response.data.data.banner ? response.data.data.banner.id : '',
          }
        });
      }
    },
    methods: {
      storeSpot() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createSpot', {
          manager: this.manager.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateSpot() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateSpot', {
          manager: this.manager.id,
          spot: this.$route.params.spot,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'spots_create';
      },
      updating() {
       return this.$route.name === 'spots_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
