import AStateList from './index'
import AStateForm from './form'

export default [
  {
    path: '/states/',
    component: AStateList,
    name: 'states',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/states/create',
    component: AStateForm,
    name: 'states_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/states/:state/update',
    component: AStateForm,
    name: 'states_update',
    meta: {
      requiresAuth: true
    }
  },
];
