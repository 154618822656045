import ABankList from './index'
import ABankForm from './form'

export default [
  {
    path: '/banks/',
    component: ABankList,
    name: 'banks',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/banks/create',
    component: ABankForm,
    name: 'banks_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/banks/:bank/update',
    component: ABankForm,
    name: 'banks_update',
    meta: {
      requiresAuth: true
    }
  },
];
