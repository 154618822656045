import APostList from './index'
import APostForm from './form'

export default [
  {
    path: '/posts/',
    component: APostList,
    name: 'posts',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/posts/create',
    component: APostForm,
    name: 'posts_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/posts/:post/update',
    component: APostForm,
    name: 'posts_update',
    meta: {
      requiresAuth: true
    }
  },
];
