import resource from '../../resource';

const state = {
  states: null,
};

const getters = {
  getStates(state) {
    return state.states;
  },
};

const mutations = {
  SET_STATES(state, data) {
    state.states = data;
  },
};

const actions = {
  fetchStates(context) {
    return resource.get(`/states`)
      .then((response) => {
        context.commit('SET_STATES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchState(context, { state }) {
    return resource.get(`/states/${state}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchStates(context, { search }) {
    return resource.get(`/states`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_STATES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toStatesPage(context, { search, page }) {
    return resource.get(`/states`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_STATES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createState(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/states`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateState(context, { manager, state, payload }) {
    return resource.put(`/managers/${manager}/states/${state}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteState(context, { manager, state }) {
    return resource.delete(`/managers/${manager}/states/${state}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
