<template>
  <v-autocomplete v-bind="$attrs" v-model="countrySelected" :items="countries" item-text="name" item-value="id" :loading="loading"/>
</template>

<script>
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-country-search',
    props: ['value'],
    data: () => ({
      countriesSearch: '',
      countrySelected: null,
      countries: [],
      loading: false,
    }),
    created() {
      this.loading = true;
      resource.get('/countries', { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.countries = response.data.data;
      });
      if (this.value) {
        this.countrySelected = this.value;
      }
    },
    watch: {
      value(value) {
        this.countrySelected = value;
      },
      countrySelected(value) {
        this.$emit('input', value);
      },
    },
    computed: {},
  }
</script>
