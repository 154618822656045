<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Método de Pagamento</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Método de Pagamento</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-select v-model="payload.type" :items="selectTypeOptions" label="Tipo*"
                            :error-messages="formErrors.type"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.code" label="Código*" type="text"
                                :error-messages="formErrors.code"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storePaymentMethod()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updatePaymentMethod()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-payment-method-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        type: '',
        code: '',
      },
      selectTypeOptions: [
        { text: 'Cartão de Crédito', value: 'CREDIT_CARD' },
        { text: 'Moeda', value: 'CURRENCY' },
        { text: 'Cartão de Debito', value: 'DEBIT_CARD' },
        { text: 'Vale Refeição', value: 'MEAL_TICKET' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchPaymentMethod', {
          paymentMethod: this.$route.params.paymentMethod
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            type: response.data.data.type,
            code: response.data.data.code,
          }
        });
      }
    },
    methods: {
      storePaymentMethod() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createPaymentMethod', {
          manager: this.manager.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updatePaymentMethod() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updatePaymentMethod', {
          manager: this.manager.id,
          paymentMethod: this.$route.params.paymentMethod,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'payment_methods_create';
      },
      updating() {
       return this.$route.name === 'payment_methods_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
