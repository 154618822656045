<template>
  <v-input :value="content" v-bind="$attrs">
    <vue-editor v-model="content" id="editor" useCustomImageHandler @image-added="handleImageAdded"
                :editor-toolbar="customToolbar"/>
  </v-input>
</template>

<script>
  import { VueEditor } from "vue2-editor";
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-editor',
    components: {VueEditor},
    props: ['value'],
    data: () => ({
      content: '',
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
    }),
    created() {
      this.content = this.value;
    },
    watch: {
      value(value) {
        this.content = value;
      },
      content(value) {
        this.$emit('input', value);
      },
    },
    methods: {
      handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        const formData = new FormData();
        formData.append("file", file);

        resource.post(`/managers/${this.manager.id}/banners`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => {
          const url = response.data.data.url;
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
