import resource from '../../resource';

const state = {
  managers: null,
};

const getters = {
  getManagers(state) {
    return state.managers;
  },
};

const mutations = {
  SET_MANAGERS(state, data) {
    state.managers = data;
  },
};

const actions = {
  fetchManagers(context) {
    return resource.get(`/managers`)
      .then((response) => {
        context.commit('SET_MANAGERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchManager(context, { manager }) {
    return resource.get(`/managers/${manager}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchManagers(context, { search }) {
    return resource.get(`/managers`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_MANAGERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toManagersPage(context, { search, page }) {
    return resource.get(`/managers`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_MANAGERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createManager(context, { payload }) {
    return resource.post(`/managers`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateManager(context, { manager, payload }) {
    return resource.put(`/managers/${manager}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteManager(context, { manager }) {
    return resource.delete(`/managers/${manager}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
