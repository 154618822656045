<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Método de Pagamento</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'payment_methods_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="paymentMethods">
      <v-col cols="12">
        <v-text-field v-model="paymentMethodsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchPaymentMethods()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="paymentMethodsHeaders"
                    :items="paymentMethods.data"
                    :page.sync="paymentMethods.meta.current_page"
                    :items-per-page.sync="paymentMethods.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.type="{ item }">
            {{ categoryType(item.type) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'payment_methods_update', params: { paymentMethod: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyPaymentMethod(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="paymentMethods.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-payment-method-list',
    data: () => ({
      page: 1,
      paymentMethodsSearch: '',
      paymentMethodsHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Tipo', value: 'type' },
        { text: 'Código', value: 'code' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toPaymentMethodsPage', {
          search: this.paymentMethodsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchPaymentMethods();
    },
    methods: {
      fetchPaymentMethods() {
        this.$store.dispatch('fetchPaymentMethods');
      },
      searchPaymentMethods() {
        this.$store.dispatch('searchPaymentMethods', {
          search: this.paymentMethodsSearch
        });
      },
      destroyPaymentMethod(paymentMethod) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deletePaymentMethod', {
            manager: this.manager.id,
            paymentMethod: paymentMethod.id
          }).then(() => {
            this.fetchPaymentMethods();
          });
        }
      },
      categoryType(type) {
        switch (type) {
          case 'CREDIT_CARD':
            return 'Cartão de Crédito';
          case 'CURRENCY':
            return 'Moeda';
          case 'DEBIT_CARD':
            return 'Cartão de Debito';
          case 'MEAL_TICKET':
            return 'Vale Refeição';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      paymentMethods() {
        return this.$store.getters.getPaymentMethods;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
