import resource from '../../resource';

const state = {
  users: null,
};

const getters = {
  getUsers(state) {
    return state.users;
  },
};

const mutations = {
  SET_USERS(state, data) {
    state.users = data;
  },
};

const actions = {
  fetchUsers(context, { manager }) {
    return resource.get(`/managers/${manager}/users`)
      .then((response) => {
        context.commit('SET_USERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchUser(context, { manager, user }) {
    return resource.get(`/managers/${manager}/users/${user}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchUsers(context, { manager, search }) {
    return resource.get(`/managers/${manager}/users`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_USERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toUsersPage(context, { manager, search, page }) {
    return resource.get(`/managers/${manager}/users`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_USERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createUser(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/users`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateUser(context, { manager, user, payload }) {
    return resource.put(`/managers/${manager}/users/${user}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteUser(context, { manager, user }) {
    return resource.delete(`/managers/${manager}/users/${user}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  loginAsUser(context, { manager, user }) {
    return resource.get(`/managers/${manager}/users/${user}/login`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
