<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar País</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar País</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.iso" label="ISO*" type="text"
                                :error-messages="formErrors.iso"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.isd" label="ISD*" type="text"
                                :error-messages="formErrors.isd"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.active" label="Ativo"
                            :error-messages="formErrors.active"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeCountry()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateCountry()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-country-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        iso: '',
        isd: '',
        active: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchCountry', {
          country: this.$route.params.country
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            iso: response.data.data.iso,
            isd: response.data.data.isd,
            active: response.data.data.active,
          }
        });
      }
    },
    methods: {
      storeCountry() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createCountry', {
          manager: this.manager.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateCountry() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateCountry', {
          manager: this.manager.id,
          country: this.$route.params.country,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'countries_create';
      },
      updating() {
       return this.$route.name === 'countries_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
