import resource from '../../resource';

const state = {
  spots: null,
};

const getters = {
  getSpots(state) {
    return state.spots;
  },
};

const mutations = {
  SET_SPOTS(state, data) {
    state.spots = data;
  },
};

const actions = {
  fetchSpots(context) {
    return resource.get(`/spots`)
      .then((response) => {
        context.commit('SET_SPOTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchSpot(context, { spot }) {
    return resource.get(`/spots/${spot}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchSpots(context, { search }) {
    return resource.get(`/spots`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_SPOTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toSpotsPage(context, { search, page }) {
    return resource.get(`/spots`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_SPOTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createSpot(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/spots`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateSpot(context, { manager, spot, payload }) {
    return resource.put(`/managers/${manager}/spots/${spot}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteSpot(context, { manager, spot }) {
    return resource.delete(`/managers/${manager}/spots/${spot}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
