import ASettings from './index'

export default [
  {
    path: '/settings',
    name: 'settings',
    component: ASettings,
    meta: {
      requiresAuth: true
    }
  },
];
