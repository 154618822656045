<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Pergunta</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Pergunta</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.question" label="Pergunta*" type="text"
                                :error-messages="formErrors.question"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="payload.resume" label="Resumo" type="text"
                                :error-messages="formErrors.resume"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea auto-grow outlined v-model="payload.answer" label="Resposta*" type="text"
                              :error-messages="formErrors.answer"
                              hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.draft" label="Rascunho"
                            :error-messages="formErrors.draft"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeQuestion()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateQuestion()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-question-form',
    data: () => ({
      formErrors: {},
      payload: {
        question: '',
        resume: '',
        answer: '',
        draft: true,
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchQuestion();
      }
    },
    methods: {
      async fetchQuestion() {
        const question = await this.$axios.get(`/managers/${this.manager.id}/faqs/${this.$route.params.faq}/questions/${this.$route.params.question}`)
        this.payload = {
          question: question.data.data.question,
          resume: question.data.data.resume,
          answer: question.data.data.answer,
          draft: question.data.data.draft,
        }
      },
      async storeQuestion() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/managers/${this.manager.id}/faqs/${this.$route.params.faq}/questions`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateQuestion() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/faqs/${this.$route.params.faq}/questions/${this.$route.params.question}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'faqs_questions_create';
      },
      updating() {
       return this.$route.name === 'faqs_questions_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
