import ABusinessSubscriptionList from './index'
import ABusinessSubscriptionForm from './form'

export default [
  {
    path: '/businesses/:business/subscriptions/',
    component: ABusinessSubscriptionList,
    name: 'businesses_subscriptions',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/businesses/:business/subscriptions/create',
    component: ABusinessSubscriptionForm,
    name: 'businesses_subscriptions_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/businesses/:business/subscriptions/:subscription/update',
    component: ABusinessSubscriptionForm,
    name: 'businesses_subscriptions_update',
    meta: {
      requiresAuth: true
    }
  },
];
