const state = {
  dashboard: {},
  dashboardLoading: false,
};

const getters = {
  getDashboardLoadingStatus(state) {
    return state.dashboardLoading;
  },
};

const mutations = {
  SET_DASHBOARD_LOADING_STATUS(state, status) {
    state.dashboardLoading = status;
  },
};

const actions = {
  fetchDashboard(context) {
    context.commit('SET_DASHBOARD_LOADING_STATUS', true);
    context.commit('SET_DASHBOARD_LOADING_STATUS', false);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};