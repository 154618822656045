<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Arquivo</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'files_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="files">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchFiles()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
                    :items="files.data"
                    :page.sync="files.meta.current_page"
                    :items-per-page.sync="files.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="6" md="3" lg="2" xl="1" v-for="item in props.items" :key="item.id">
                <v-card flat outlined>
                  <v-img :src="item.url" :aspect-ratio="1" contain v-if="item.mime_type.includes('image')"/>
                  <v-responsive :aspect-ratio="1" v-else>
                    <v-sheet color="primary" class="d-flex fill-height white--text text-uppercase align-center justify-center">
                      <v-icon size="50" color="#FFFFFF" v-if="item.mime_type.includes('audio')">mdi-volume-high</v-icon>
                      <v-icon size="50" color="#FFFFFF" v-else-if="item.mime_type.includes('video')">mdi-play-circle-outline</v-icon>
                      <span class="d-block text-h4" v-else>{{ item.extension }}</span>
                    </v-sheet>
                  </v-responsive>
                  <v-card-actions>
                    <span class="d-inline-block text-truncate">
                      {{ item.name }}
                    </span>
                    <v-spacer/>
                    <v-btn :to="{ name: 'files_update', params: { file: item.id } }" icon class="mr-0">
                      <v-icon color="primary">mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <v-btn @click="destroyFile(item)" icon class="mr-0">
                      <v-icon color="primary">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="files.meta.last_page" :total-visible="7" @input="fetchFiles()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-file-list',
    data: () => ({
      files: null,
      page: 1,
      search: '',
      imageTypes: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'],
      videoTypes: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'],
      audioTypes: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'],
    }),
    created() {
      this.fetchFiles();
    },
    methods: {
      async fetchFiles() {
        const files = await this.$axios.get(`/managers/${this.manager.id}/files`, { params: { name: this.search, page: this.page } })
        this.files = files.data;
      },
      async destroyFile(file) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/managers/${this.manager.id}/files/${file.id}`)
          await this.fetchFiles();
        }
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
