<template>
  <v-row align-content="center" justify="center" class="fill-height">
    <v-col cols="12" sm="8" md="4" style="max-width: 400px;">
      <v-sheet class="pa-5 transparent">
        <v-form ref="form" v-model="valid" @keyup.native.enter="resetPassword">
          <v-row dense>
            <v-col cols="12">
              <v-text-field flat solo v-model="credentials.email" label="Email*" type="email" hide-details="auto" :rules="[$rules.required]"/>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn text class="mr-1" @click="$router.back()">Voltar</v-btn>
              <v-btn @click="resetPassword" color="primary">Enviar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-reset-password',
  data: () => ({
    credentials: {
      email: '',
    },
    valid: true,
  }),
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('resetPassword', this.credentials)
          .then(() => {
            this.$refs.form.reset();
            this.$store.dispatch('loginRemoveSession');
            this.$router.push({ name: 'email_alert' });
          }).catch(() => {
            alert('Erro no Resetar Senha, Tente Novamente!');
          });
      }
    },
  },
};
</script>
