<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>País</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'countries_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="countries">
      <v-col cols="12">
        <v-text-field v-model="countriesSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchCountries()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="countriesHeaders"
                    :items="countries.data"
                    :page.sync="countries.meta.current_page"
                    :items-per-page.sync="countries.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'countries_update', params: { country: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyCountry(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="countries.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-country-list',
    data: () => ({
      page: 1,
      countriesSearch: '',
      countriesHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'ISO', value: 'iso' },
        { text: 'ISD', value: 'isd' },
        { text: 'Ativo', value: 'active' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toCountriesPage', {
          search: this.countriesSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchCountries();
    },
    methods: {
      fetchCountries() {
        this.$store.dispatch('fetchCountries');
      },
      searchCountries() {
        this.$store.dispatch('searchCountries', {
          search: this.countriesSearch
        });
      },
      destroyCountry(country) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteCountry', {
            manager: this.manager.id,
            country: country.id
          }).then(() => {
            this.fetchCountries();
          });
        }
      },
    },
    computed: {
      countries() {
        return this.$store.getters.getCountries;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
