<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Plano</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Plano</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="payload.interval" label="Intervalo*" :items="intervals"
                            :error-messages="formErrors.interval"
                            hide-details="auto" :rules="creating ? [$rules.required] : []" :disabled="updating"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.iterations" label="Número de Parcelas*" type="number"
                                :error-messages="formErrors.iterations"
                                hide-details="auto" :rules="creating ? [$rules.required] : []" :disabled="updating"
                                :suffix="iterations(payload.interval)"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.amount" label="Valor*" type="number"
                                :error-messages="formErrors.amount"
                                hide-details="auto" :rules="creating ? [$rules.required] : []" :disabled="updating"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.trial_period_days" label="Dias de Teste*"  type="number"
                                :error-messages="formErrors.trial_period_days"
                                hide-details="auto" :rules="creating ? [$rules.required] : []" :disabled="updating"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.valid_until" label="Validade" type="datetime-local" step="1"
                                :error-messages="formErrors.valid_until"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.active" label="Ativo"
                            :error-messages="formErrors.active"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storePlan()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updatePlan()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-plan-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        active: '',
        public: false,
        interval: 'MONTH',
        iterations: '',
        amount: '',
        trial_period_days: '',
        valid_until: '',
      },
      intervals: [
        { text: 'Mensal', value: 'MONTH' },
        { text: 'Anual', value: 'YEAR' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchPlan();
      }
    },
    methods: {
      async fetchPlan() {
        const plan = await this.$axios.get(`/managers/${this.manager.id}/plans/${this.$route.params.plan}`)
        this.payload = {
          name: plan.data.data.name,
          active: plan.data.data.active,
          public: plan.data.data.public,
          interval: plan.data.data.interval,
          iterations: plan.data.data.iterations,
          amount: plan.data.data.amount,
          trial_period_days: plan.data.data.trial_period_days,
          valid_until: plan.data.data.valid_until,
        }
      },
      async storePlan() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/managers/${this.manager.id}/plans`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updatePlan() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/plans/${this.$route.params.plan}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      iterations(interval) {
        switch (interval) {
          case 'MONTH':
            return 'Mês(es)';
          case 'YEAR':
            return 'Ano(s)';
          default:
            return 'Selecione o Intervalo';
        }
      }
    },
    computed: {
      creating() {
        return this.$route.name === 'plans_create';
      },
      updating() {
       return this.$route.name === 'plans_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
