import { DateTime } from "luxon";

const state = {
  drawer: null,
  preventLoadingDialog: false,
  loadingDialog: false,
  currentPosition: null,
  showSnackbar: false,
  snackbarText: '',
  timezone: DateTime.now().toFormat('z'),
  GMT: DateTime.now().toFormat('ZZ'),
  appName: process.env.VUE_APP_APP,
  appVersion: process.env.VUE_APP_VERSION,
  firebaseConfig: {
    apiKey: "AIzaSyCGSmMZTWq47QchNYF6cKFadM35h8cS4NI",
    authDomain: "pointurbano-42108.firebaseapp.com",
    databaseURL: "https://pointurbano-42108.firebaseio.com",
    projectId: "pointurbano-42108",
    storageBucket: "pointurbano-42108.appspot.com",
    messagingSenderId: "815664903213",
    appId: "1:815664903213:web:54c1779a8fe3aa5a99b4a4",
    measurementId: "G-JHMFYXZK6G"
  }
};

const getters = {
  getDrawerStatus(state) {
    return state.drawer;
  },
  getLoadingDialog(state) {
    return state.loadingDialog;
  },
  getPreventLoadingDialog(state) {
    return state.preventLoadingDialog;
  },
  getCurrentPosition(state) {
    return state.currentPosition;
  },
  getShowSnackbar(state) {
    return state.showSnackbar;
  },
  getSnackbarText(state) {
    return state.snackbarText;
  },
  getTimezone(state) {
    return state.timezone;
  },
  getGMT(state) {
    return state.GMT;
  },
  getAppName(state) {
    return state.appName;
  },
  getAppVersion(state) {
    return state.appVersion;
  },
  getFirebaseConfig(state) {
    return state.firebaseConfig;
  }
};

const mutations = {
  SET_DRAWER_STATUS(state, status) {
    state.drawer = status;
  },
  SET_LOADING_DIALOG_STATUS(state, status) {
    state.loadingDialog = status;
  },
  SET_PREVENT_LOADING_DIALOG_STATUS(state, status) {
    state.preventLoadingDialog = status;
  },
  SET_CURRENT_POSITION(state, status) {
    state.currentPosition = status;
  },
  SET_SHOW_SNACKBAR_STATUS(state, status) {
    state.showSnackbar = status;
  },
  SET_SNACKBAR_TEXT(state, text) {
    state.snackbarText = text;
  },
  SET_TIMEZONE(state, data) {
    state.timezone = data;
  },
  SET_GMT(state, data) {
    state.GMT = data;
  },
};

const actions = {
  setDrawerStatus(context, status) {
    context.commit('SET_DRAWER_STATUS', status);
  },
  setLoadingDialogStatus(context, status) {
    context.commit('SET_LOADING_DIALOG_STATUS', status);
  },
  setPreventLoadingDialogStatus(context, status) {
    context.commit('SET_PREVENT_LOADING_DIALOG_STATUS', status);
  },
  setCurrentPosition(context, status) {
    context.commit('SET_CURRENT_POSITION', status);
  },
  setShowSnackbarStatus(context, status) {
    context.commit('SET_SHOW_SNACKBAR_STATUS', status);
  },
  setSnackbarText(context, text) {
    context.commit('SET_SNACKBAR_TEXT', text);
  },
  setTimezone(context, data) {
    context.commit('SET_TIMEZONE', data);
  },
  setGMT(context, data) {
    context.commit('SET_GMT', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
