<template>
  <v-card flat outlined class="fill-height" v-if="telemetry">
    <v-card-title>
      Estabelecimentos
    </v-card-title>
    <v-card-subtitle>
      <v-chip label class="mr-1 mt-1" color="primary" text-color="white">
        <v-avatar rounded left class="primary darken-4">{{ telemetry.quantity }}</v-avatar>
        Cadastrados
      </v-chip>
      <v-chip label class="mr-1 mt-1" color="primary" text-color="white">
        <v-avatar rounded left class="primary darken-4">{{ telemetry.online }}</v-avatar>
        Online
      </v-chip>
      <v-chip label class="mr-1 mt-1" color="primary" text-color="white">
        <v-avatar rounded left class="primary darken-4">{{ telemetry.offline }}</v-avatar>
        Offline
      </v-chip>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'a-telemetry-online-businesses',
  data: () => ({
    telemetry: null,
  }),
  created() {
    this.$axios.get(`/managers/${this.manager.id}/telemetry/online_businesses`)
      .then((response) => {
        this.telemetry = response.data;
      });
  },
  computed: {
    manager() {
      return this.$store.getters.getLoginAuthManager;
    },
  }
};
</script>
