<template>
  <v-responsive v-bind="$attrs">
    <div class="leaflet-map" id="map-marker-cluster"></div>
  </v-responsive>
</template>

<script>
  import * as L from 'leaflet';
  import { GestureHandling } from 'leaflet-gesture-handling';

  import 'leaflet-fullscreen';
  import 'leaflet.markercluster';

  import 'leaflet/dist/leaflet.css';
  import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
  import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
  import 'leaflet.markercluster/dist/MarkerCluster.css';

  export default {
    inheritAttrs: false,
    name: 'a-leaflet-marker-cluster',
    data: () => ({
      map: null,
      cluster: null,
      tiles: null,
      style: {
        color: '#D32F2F',
        weight: 2,
        opacity: 0.65
      },
      myIcon: L.icon({
        iconUrl: require('../assets/marker-icon.png'),
        iconRetinaUrl: require('../assets/marker-icon-2x.png'),
        shadowUrl: require('../assets/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      })
    }),
    mounted() {
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

      L.Marker.prototype.options.icon = this.myIcon;

      this.map = L.map('map-marker-cluster', {
        gestureHandling: false,
        fullscreenControl: true
      });

      this.tiles = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        minZoom: 10,
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: '&copy; <a href="https://www.google.com">Google</a>',
      }).addTo(this.map);

      // this.tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   minZoom: 15,
      //   maxZoom: 18,
      //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      // }).addTo(this.map);

      this.changePosition();

      this.map.on('moveend', () => {
        const area = L.rectangle(this.map.getBounds()).toGeoJSON().geometry;
        this.getBusinesses(area);
      });

    },
    methods: {
      async getBusinesses(area) {
        const businesses = await this.$axios.get(`/businesses`, { params: {area: area, active: 1, subscribed: 1 }, loader: false });

        if (this.cluster) {
          this.map.removeLayer(this.cluster);
          this.cluster = null;
        }

        const markers = L.markerClusterGroup({
          spiderfyOnMaxZoom: true,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: true,
        });

        businesses.data.data.forEach(item => {
          if (item.location) {
            const avatar = item.avatar ? item.avatar.url : require('../assets/placeholder.png');

            const styledIcon = new L.DivIcon({
              className: 'my-div-icon',
              html: `<img class="my-div-image" src="${avatar}"/><span class="my-div-span">${item.name}</span>`
            })

            markers.addLayer(L.marker(L.latLng(item.location.coordinates[1], item.location.coordinates[0]), { icon: styledIcon }));
          }
        })

        this.cluster = markers;
        this.map.addLayer(markers);
      },
      changePosition() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.map.setView(L.latLng(position.coords.latitude, position.coords.longitude), 18);
          }, () => {
            this.map.setView(L.latLng(-13.882244132383343, -53.92089843750001), 5);
          });
        } else {
          this.map.setView(L.latLng(-13.882244132383343, -53.92089843750001), 5);
        }
      },
    }
  }
</script>

<style>
.my-div-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-div-icon .my-div-image {
  width: 40px;
  height: 40px;
  border: #FFFFFF 4px solid;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.my-div-icon .my-div-span {
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #000000;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  text-decoration: none;
  border-radius: 5px;
  padding: 2px 6px;
  margin-top: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large,
.leaflet-oldie .marker-cluster-small,
.leaflet-oldie .marker-cluster-medium,
.leaflet-oldie .marker-cluster-large {
  background-color: rgb(211, 47, 47);
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div,
.leaflet-oldie .marker-cluster-small div,
.leaflet-oldie .marker-cluster-medium div,
.leaflet-oldie .marker-cluster-large div {
  /*background-color: rgba(211, 47, 47);*/
  background-color: rgb(90, 0, 0);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Roboto", sans-serif;
  font-weight: bold;
  color: #FFFFFF;
}

.marker-cluster span {
  line-height: 30px;
}
</style>