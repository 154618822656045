import AWaiterList from './index'
import AWaiterForm from './form'

export default [
  {
    path: '/waiters/',
    component: AWaiterList,
    name: 'waiters',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/waiters/create',
    component: AWaiterForm,
    name: 'waiters_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/waiters/:waiter/update',
    component: AWaiterForm,
    name: 'waiters_update',
    meta: {
      requiresAuth: true
    }
  },
];
