import ABusinessList from './index'
import ABusinessForm from './form'

export default [
  {
    path: '/businesses/',
    component: ABusinessList,
    name: 'businesses',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/businesses/create',
    component: ABusinessForm,
    name: 'businesses_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/businesses/:business/update',
    component: ABusinessForm,
    name: 'businesses_update',
    meta: {
      requiresAuth: true
    }
  },
];
