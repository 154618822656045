<template>
  <v-row dense align-content="center" justify="center" no-gutters class="fill-height" style="width: 100%;">
    <template v-if="$route.query.email">
      <v-col cols="12" class="text-center">
        <v-avatar tile size="100">
          <img :src="require('../../assets/logo.png')" alt="Autabit">
        </v-avatar>
        <span class="d-block font-weight-light">Um email foi enviado para {{ $route.query.email }} com o código de verificação.</span>
      </v-col>
      <v-col cols="10" sm="6" md="4" lg="3" xl="2" class="pt-4">
        <v-otp-input v-model="temporaryCode" :length="length" type="number"/>
        <v-row dense>
          <v-col cols="12" v-if="activeVerify">
            <v-btn depressed block color="primary" @click="verifyEmail()">Continuar</v-btn>
          </v-col>
          <v-col cols="12" v-if="message">
            <v-alert type="success" dense class="mb-0">O código foi enviado com sucesso!</v-alert>
          </v-col>
          <v-col cols="12">
            <v-btn text block @click="resetTemporaryCode()">Reenviar código</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12" class="text-center">
        <h5 class="text-h5 font-weight-light">Email não encontrado!</h5>
      </v-col>
      <v-col cols="12" class="pt-4 text-center">
        <v-btn depressed color="primary" @click="$router.back()">Voltar</v-btn>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'a-email-confirm',
  data: () => ({
    temporaryCode: '',
    length: 6,
    message: false,
  }),
  methods: {
    async verifyEmail() {
      await this.$axios.post(`auth/manager/verify_email`, {
        temporary_code: this.temporaryCode,
        email: this.$route.query.email,
      });
      await this.$router.push({ name: 'login' });
    },
    async resetTemporaryCode() {
      await this.$axios.put(`auth/manager/reset_temporary_code`, {
        via: 'email',
        email: this.$route.query.email,
      });
      this.message = true;
    },
  },
  computed: {
    activeVerify () {
      return this.temporaryCode.length === this.length
    },
  },
};
</script>
