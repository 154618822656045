import ABannerList from './index'
import ABannerForm from './form'

export default [
  {
    path: '/banners/',
    component: ABannerList,
    name: 'banners',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/banners/create',
    component: ABannerForm,
    name: 'banners_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/banners/:banner/update',
    component: ABannerForm,
    name: 'banners_update',
    meta: {
      requiresAuth: true
    }
  },
];
