import AUserList from './index'
import AUserForm from './form'

export default [
  {
    path: '/users/',
    component: AUserList,
    name: 'users',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/users/create',
    component: AUserForm,
    name: 'users_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:user/update',
    component: AUserForm,
    name: 'users_update',
    meta: {
      requiresAuth: true
    }
  },
];
