<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Inscrição</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Inscrição</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Programa de nível gratuito do PointUrbano</h1>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="payload.status" :items="statuses"
                            :error-messages="formErrors.status"
                            label="Status*" hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  Programa de nível gratuito do PointUrbano
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeSubscription()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateSubscription()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-subscription-form',
    data: () => ({
      formErrors: {},
      payload: {
        status: 'FREE_TIER',
      },
      statuses: [
        { text: 'Nível Gratuito', value: 'FREE_TIER' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchSubscription();
      }
    },
    methods: {
      async fetchSubscription() {
        const subscription = await this.$axios.get(`/managers/${this.manager.id}/businesses/${this.$route.params.business}/subscriptions/${this.$route.params.subscription}`)
        this.payload = {
          status: subscription.data.data.status,
        }
      },
      async storeSubscription() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/managers/${this.manager.id}/businesses/${this.$route.params.business}/subscriptions`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateSubscription() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/businesses/${this.$route.params.business}/subscriptions/${this.$route.params.subscription}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'businesses_subscriptions_create';
      },
      updating() {
       return this.$route.name === 'businesses_subscriptions_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
