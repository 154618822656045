import AFileList from './index'
import AFileForm from './form'

export default [
  {
    path: '/files/',
    component: AFileList,
    name: 'files',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/files/create',
    component: AFileForm,
    name: 'files_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/files/:file/update',
    component: AFileForm,
    name: 'files_update',
    meta: {
      requiresAuth: true
    }
  },
];
