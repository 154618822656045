import ACookieList from './index'
import ACookieForm from './form'

export default [
  {
    path: '/cookies/',
    component: ACookieList,
    name: 'cookies',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/cookies/create',
    component: ACookieForm,
    name: 'cookies_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cookies/:cookie/update',
    component: ACookieForm,
    name: 'cookies_update',
    meta: {
      requiresAuth: true
    }
  },
];
