import AFaqQuestionList from './index'
import AFaqQuestionForm from './form'

export default [
  {
    path: '/faqs/:faq/questions/',
    component: AFaqQuestionList,
    name: 'faqs_questions',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/faqs/:faq/questions/create',
    component: AFaqQuestionForm,
    name: 'faqs_questions_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/faqs/:faq/questions/:question/update',
    component: AFaqQuestionForm,
    name: 'faqs_questions_update',
    meta: {
      requiresAuth: true
    }
  },
];
