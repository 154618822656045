<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Banner</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'banners_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="banners">
      <v-col cols="12">
        <v-text-field v-model="bannersSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchBanners()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
            :items="banners.data"
            :page.sync="banners.meta.current_page"
            :items-per-page.sync="banners.meta.per_page"
            hide-default-footer
            disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="6" md="3" lg="2" xl="1" v-for="item in props.items" :key="item.id">
                <v-card flat outlined>
                  <v-img :src="item.url" :aspect-ratio="1" contain/>
                  <v-card-actions>
                    <span class="d-inline-block text-truncate">
                      {{ item.name }}
                    </span>
                    <v-spacer/>
                    <v-btn :to="{ name: 'banners_update', params: { banner: item.id } }" icon class="mr-0">
                      <v-icon color="primary">mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <v-btn @click="destroyBanner(item)" icon class="mr-0">
                      <v-icon color="primary">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="banners.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-banner-list',
    data: () => ({
      page: 1,
      bannersSearch: '',
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toBannersPage', {
          manager: this.manager.id,
          search: this.bannersSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchBanners();
    },
    methods: {
      fetchBanners() {
        this.$store.dispatch('fetchBanners', {
          manager: this.manager.id,
        });
      },
      searchBanners() {
        this.$store.dispatch('searchBanners', {
          manager: this.manager.id,
          search: this.bannersSearch
        });
      },
      destroyBanner(banner) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteBanner', {
            manager: this.manager.id,
            banner: banner.id
          }).then(() => {
            this.fetchBanners();
          });
        }
      },
    },
    computed: {
      banners() {
        return this.$store.getters.getBanners;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
