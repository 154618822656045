import resource from '../../resource';

const state = {
  banners: null,
};

const getters = {
  getBanners(state) {
    return state.banners;
  },
};

const mutations = {
  SET_BANNERS(state, data) {
    state.banners = data;
  },
};

const actions = {
  fetchBanners(context, { manager }) {
    return resource.get(`/managers/${manager}/banners`)
      .then((response) => {
        context.commit('SET_BANNERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchBanner(context, { manager, banner }) {
    return resource.get(`/managers/${manager}/banners/${banner}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchBanners(context, { manager, search }) {
    return resource.get(`/managers/${manager}/banners`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_BANNERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toBannersPage(context, { manager, search, page }) {
    return resource.get(`/managers/${manager}/banners`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_BANNERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createBanner(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/banners`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateBanner(context, { manager, banner, payload }) {
    return resource.put(`/managers/${manager}/banners/${banner}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteBanner(context, { manager, banner }) {
    return resource.delete(`/managers/${manager}/banners/${banner}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
