<template>
  <v-row dense align-content="center" justify="center" no-gutters class="fill-height" style="width: 100%;">
    <v-col cols="12" class="text-center">
      <v-avatar tile size="100">
        <img :src="require('../../assets/logo.png')" alt="Autabit">
      </v-avatar>
      <h3 class="text-h3 font-weight-light">Um email foi enviado</h3>
      <h6 class="text-h6 font-weight-light">Vá até a caixa de entrada do seu email para continuar o processo!</h6>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-email-alert'
};
</script>
