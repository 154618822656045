<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <a-profile-avatar-update/>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <v-card flat outlined class="fill-height" :to="{ name: 'profile_about' }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Informações Pessoais</v-list-item-title>
            <v-list-item-subtitle>Altere nome, visibilidade.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <v-card flat outlined class="fill-height" :to="{ name: 'profile_security' }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Dados de Segurança</v-list-item-title>
            <v-list-item-subtitle>Altere senhas de acesso e email.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import AProfileAvatarUpdate from "@/components/Profile/AProfileAvatarUpdate";

  export default {
    name: 'a-manager',
    components: {AProfileAvatarUpdate},
    methods: {
      revoke() {
        if (confirm('Tem certeza que deseja sair de todos os aplicativos?')) {
          this.$store.dispatch('loginRemoveSessions')
            .finally(() => {
              this.$store.dispatch('setDrawerStatus', null);
              this.$router.push({name: 'login'});
            });
        }
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
