<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-switch  color="primary" label="Tema Escuro" v-model="themeDark"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-settings',
  computed: {
    themeDark: {
      get: function() {
        return this.$store.getters.getThemeDark;
      },
      set: function(status) {
        this.$store.dispatch('setThemeDark', status);
      },
    },
  },
};
</script>
