<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Dados Pessoais</h1>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model.trim="payload.name" label="Nome completo*"
                              :error-messages="formErrors.name"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model.trim="payload.birth_date" label="Data de nascimento*" type="date"
                              :error-messages="formErrors.birth_date"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="payload.sex" :items="sexTypes" label="Sexo*"
                          :error-messages="formErrors.sex"
                          hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'a-profile-about-update',
    data: () => ({
      formErrors: {},
      dialog: false,
      payload: null,
      sexTypes: [
        { text: 'Masculino', value: 'MALE' },
        { text: 'Feminino', value: 'FEMALE' },
      ],
      valid: true,
    }),
    created() {
      this.$store.dispatch('fetchProfile', {
        manager: this.manager.id
      }).then((response) => {
        this.payload = {
          name: response.data.data.name,
          birth_date: response.data.data.birth_date,
          sex: response.data.data.sex,
          address_id: response.data.data.address ? response.data.data.address.id : '',
          telephone_id: response.data.data.telephone ? response.data.data.telephone.id : '',
          avatar_id: response.data.data.avatar ? response.data.data.avatar.id : '',
        }
      });
    },
    methods: {
      updateProfile() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateProfile', {
          manager: this.manager.id,
          payload: this.payload
        }).then((response) => {
          this.$refs.form.reset();
          this.$store.dispatch('loginSetUser', response.data.data);
          this.$router.back();
        }).catch((error) => {
          if(error.response && error.response.status === 422){
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    }
  };
</script>
