import ACountryList from './index'
import ACountryForm from './form'

export default [
  {
    path: '/countries/',
    component: ACountryList,
    name: 'countries',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/countries/create',
    component: ACountryForm,
    name: 'countries_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/countries/:country/update',
    component: ACountryForm,
    name: 'countries_update',
    meta: {
      requiresAuth: true
    }
  },
];
