import ATermList from './index'
import ATermForm from './form'

export default [
  {
    path: '/terms/',
    component: ATermList,
    name: 'terms',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/terms/create',
    component: ATermForm,
    name: 'terms_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terms/:term/update',
    component: ATermForm,
    name: 'terms_update',
    meta: {
      requiresAuth: true
    }
  },
];
