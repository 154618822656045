import Vue from 'vue';
import Vuex from 'vuex';

import application from "../views/Application/store.module";
import bank from "../views/Bank/store.module";
import banner from "../views/Banner/store.module";
import business from "../views/Business/store.module";
import category from "../views/Category/store.module";
import city from "../views/City/store.module";
import country from "../views/Country/store.module";
import dashboard from "../views/Dashboard/store.module";
import driver from "../views/Driver/store.module";
import httpNotFound from "../views/HttpNotFound/store.module";
import login from "../views/Login/store.module";
import manager from "../views/Manager/store.module";
import paymentMethod from "../views/PaymentMethod/store.module";
import post from "../views/Post/store.module";
import profile from "../views/Profile/store.module";
import resetPassword from "../views/ResetPassword/store.module";
import settings from "../views/Settings/store.module";
import spot from "../views/Spot/store.module";
import state from "../views/State/store.module";
import user from "../views/User/store.module";
import waiter from "../views/Waiter/store.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    bank,
    banner,
    business,
    category,
    city,
    country,
    dashboard,
    driver,
    httpNotFound,
    login,
    manager,
    paymentMethod,
    post,
    profile,
    resetPassword,
    settings,
    spot,
    state,
    user,
    waiter,
  },
})
