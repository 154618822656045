import resource from '../../resource';

const state = {
  posts: null,
};

const getters = {
  getPosts(state) {
    return state.posts;
  },
};

const mutations = {
  SET_POSTS(state, data) {
    state.posts = data;
  },
};

const actions = {
  fetchPosts(context) {
    return resource.get(`/posts`)
      .then((response) => {
        context.commit('SET_POSTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchPost(context, { post }) {
    return resource.get(`/posts/${post}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchPosts(context, { search }) {
    return resource.get(`/posts`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_POSTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toPostsPage(context, { search, page }) {
    return resource.get(`/posts`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_POSTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createPost(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/posts`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updatePost(context, { manager, post, payload }) {
    return resource.put(`/managers/${manager}/posts/${post}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deletePost(context, { manager, post }) {
    return resource.delete(`/managers/${manager}/posts/${post}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
