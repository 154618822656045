import AManagerList from './index'
import AManagerForm from './form'

export default [
  {
    path: '/managers/',
    component: AManagerList,
    name: 'managers',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/managers/create',
    component: AManagerForm,
    name: 'managers_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/managers/:manager/update',
    component: AManagerForm,
    name: 'managers_update',
    meta: {
      requiresAuth: true
    }
  },
];
