import resource from '../../resource';

const state = {
  banks: null,
};

const getters = {
  getBanks(state) {
    return state.banks;
  },
};

const mutations = {
  SET_BANKS(state, data) {
    state.banks = data;
  },
};

const actions = {
  fetchBanks(context) {
    return resource.get(`/banks`)
      .then((response) => {
        context.commit('SET_BANKS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchBank(context, { bank }) {
    return resource.get(`/banks/${bank}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchBanks(context, { search }) {
    return resource.get(`/banks`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_BANKS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toBanksPage(context, { search, page }) {
    return resource.get(`/banks`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_BANKS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createBank(context, { manager, payload }) {
    return resource.post(`/managers/${manager}/banks`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateBank(context, { manager, bank, payload }) {
    return resource.put(`/managers/${manager}/banks/${bank}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteBank(context, { manager, bank }) {
    return resource.delete(`/managers/${manager}/banks/${bank}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
