import axios from 'axios';
import store from '../store';
import router from '../router';

const resource = axios.create({
  loader: true,
  baseURL: `${process.env.VUE_APP_HOST}/api/v1`,
  headers: {
    'Content-Type' : 'application/json',
    'Accept' : 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  }
});

//INTERCEPTOR REQUEST
resource.interceptors.request.use((config) => {
  store.dispatch('setLoadingDialogStatus', config.loader);
  config.headers.Authorization = `Bearer ${store.getters.getLoginToken}`;
  config.headers.Timezone = store.getters.getTimezone;
  config.headers.GMT = store.getters.getGMT;
  return config;
}, (error) => {
  alert('Não conseguimos processar sua requisição, tente novamente mais tarde.');
  store.dispatch('setLoadingDialogStatus', false);
  Promise.reject(error)
});

//INTERCEPTOR RESPONSE
resource.interceptors.response.use((response) => {
  store.dispatch('setLoadingDialogStatus', false);
  return response;
}, (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        alert(error.response.data ? error.response.data.message : 'Ops.. algo inesperado aconteceu.');
        break;
      case 401:
        store.dispatch('loginRemoveToken').then(() => {
          router.push({name: 'login'});
        });
        break;
      case 403:
        alert('Você não tem permissão para continuar.');
        break;
      case 422:
        alert('Algumas informações fornecidas estão incorretas.');
        break;
      case 500:
        alert('O servidor não conseguiu processar sua requisição no momento.');
        break;
    }
  } else if (error.request) {
    alert('Não conseguimos processar sua requisição.');
  } else {
    alert('Algo deu errado.');
  }

  store.dispatch('setLoadingDialogStatus', false);

  return Promise.reject(error);
});

export default resource;
