import APolicyList from './index'
import APolicyForm from './form'

export default [
  {
    path: '/policies/',
    component: APolicyList,
    name: 'policies',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/policies/create',
    component: APolicyForm,
    name: 'policies_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/policies/:policy/update',
    component: APolicyForm,
    name: 'policies_update',
    meta: {
      requiresAuth: true
    }
  },
];
