<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>FAQ</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'faqs_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="faqs">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchFaqs()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="headers"
                    :items="faqs.data"
                    :page.sync="faqs.meta.current_page"
                    :items-per-page.sync="faqs.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.draft="{ item }">
            <v-simple-checkbox v-model="item.draft" disabled/>
          </template>
          <template v-slot:item.manager="{ item }">
            {{ item.manager.name }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'faqs_update', params: { faq: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'faqs_questions', params: { faq: item.id } }" icon class="mr-0">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
            <v-btn @click="destroyFaq(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="faqs.meta.last_page" :total-visible="7" @input="fetchFaqs()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-faq-list',
    data: () => ({
      faqs: null,
      page: 1,
      search: '',
      headers: [
        { text: 'Título', value: 'title' },
        { text: 'Resumo', value: 'resume' },
        { text: 'Rascunho', value: 'draft' },
        { text: 'Autor', value: 'manager' },
        { text: 'Ação', align: 'center', width: 180, value: 'action' },
      ],
    }),
    created() {
      this.fetchFaqs();
    },
    methods: {
      async fetchFaqs() {
        const faqs = await this.$axios.get(`/faqs`, { params: { title: this.search, page: this.page } })
        this.faqs = faqs.data;
      },
      async destroyFaq(faq) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/managers/${this.manager.id}/faqs/${faq.id}`)
          await this.fetchFaqs();
        }
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
