<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Termo de Uso</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Termo de Uso</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.title" label="Título*" type="text"
                                :error-messages="formErrors.title"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="payload.version" label="Versão*" type="number"
                                :error-messages="formErrors.version"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Conteúdo</h1>
                </v-col>
                <v-col cols="12">
                  <a-editor auto-grow outlined v-model="payload.content" type="text"
                            :error-messages="formErrors.content"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.revoked" label="Revogado"
                            :error-messages="formErrors.revoked"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeTerm()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateTerm()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import AEditor from "../../components/AEditor";

  export default {
    name: 'a-term-form',
    components: {AEditor},
    data: () => ({
      formErrors: {},
      payload: {
        title: '',
        version: 0,
        revoked: false,
        content: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchTerm();
      }
    },
    methods: {
      async fetchTerm() {
        const term = await this.$axios.get(`/terms/${this.$route.params.term}`)
        this.payload = {
          title: term.data.data.title,
          version: term.data.data.version,
          revoked: term.data.data.revoked,
          content: term.data.data.content,
        }
      },
      async storeTerm() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/managers/${this.manager.id}/terms`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateTerm() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/terms/${this.$route.params.term}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'terms_create';
      },
      updating() {
       return this.$route.name === 'terms_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
