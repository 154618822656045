<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar FAQ</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar FAQ</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.title" label="Títilo*" type="text"
                                :error-messages="formErrors.title"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="payload.resume" label="Resumo" type="text"
                                :error-messages="formErrors.resume"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.draft" label="Rascunho"
                            :error-messages="formErrors.draft"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeFaq()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateFaq()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-faq-form',
    data: () => ({
      formErrors: {},
      payload: {
        title: '',
        resume: '',
        draft: true,
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchFaq();
      }
    },
    methods: {
      async fetchFaq() {
        const faq = await this.$axios.get(`/faqs/${this.$route.params.faq}`)
        this.payload = {
          title: faq.data.data.title,
          resume: faq.data.data.resume,
          draft: faq.data.data.draft,
        }
      },
      async storeFaq() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/managers/${this.manager.id}/faqs`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateFaq() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/managers/${this.manager.id}/faqs/${this.$route.params.faq}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'faqs_create';
      },
      updating() {
       return this.$route.name === 'faqs_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
