import Vue from 'vue'
import store from '../store';
import Router from 'vue-router'

import application from "../views/Application/router";
import bank from "../views/Bank/router";
import banner from "../views/Banner/router";
import business from "../views/Business/router";
import businessPlan from "../views/BusinessPlan/router";
import businessSubscription from "../views/BusinessSubscription/router";
import category from "../views/Category/router";
import city from "../views/City/router";
import contact from "../views/Contact/router";
import cookie from "../views/Cookie/router";
import country from "../views/Country/router";
import dashboard from "../views/Dashboard/router";
import driver from "../views/Driver/router";
import emailAlert from "../views/EmailAlert/router";
import emailConfirm from "../views/EmailConfirm/router";
import faq from "../views/Faq/router";
import faqQuestion from "../views/FaqQuestion/router";
import file from "../views/File/router";
import httpNotFound from "../views/HttpNotFound/router";
import login from "../views/Login/router";
import manager from "../views/Manager/router";
import paymentMethod from "../views/PaymentMethod/router";
import plan from "../views/Plan/router";
import policy from "../views/Policy/router";
import post from "../views/Post/router";
import profile from "../views/Profile/router";
import resetPassword from "../views/ResetPassword/router";
import settings from "../views/Settings/router";
import spot from "../views/Spot/router";
import state from "../views/State/router";
import term from "../views/Term/router";
import user from "../views/User/router";
import waiter from "../views/Waiter/router";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard', params: { status: 'PENDING' } }
    },
    ...application,
    ...bank,
    ...banner,
    ...business,
    ...businessPlan,
    ...businessSubscription,
    ...category,
    ...city,
    ...contact,
    ...cookie,
    ...country,
    ...dashboard,
    ...driver,
    ...emailAlert,
    ...emailConfirm,
    ...faq,
    ...faqQuestion,
    ...file,
    ...httpNotFound,
    ...login,
    ...manager,
    ...paymentMethod,
    ...plan,
    ...policy,
    ...post,
    ...profile,
    ...resetPassword,
    ...settings,
    ...spot,
    ...state,
    ...term,
    ...user,
    ...waiter,
    {
      path: '*',
      name: 'not-found',
      redirect: { name: 'http_not_found' },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  let authCheck = store.getters.getLoginAuthCheck;
  let auth = to.matched.some(record => record.meta.requiresAuth);

  if (auth && !authCheck) {
    next({ name: 'login' })
  } else {
    next();
  }
});

export default router;
