import ABusinessPlanList from './index'
import ABusinessPlanForm from './form'

export default [
  {
    path: '/businesses/:business/plans/',
    component: ABusinessPlanList,
    name: 'businesses_plans',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/businesses/:business/plans/create',
    component: ABusinessPlanForm,
    name: 'businesses_plans_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/businesses/:business/plans/:plan/update',
    component: ABusinessPlanForm,
    name: 'businesses_plans_update',
    meta: {
      requiresAuth: true
    }
  },
];
