<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12" sm="4">
      <a-telemetry-online-businesses/>
    </v-col>
    <v-col cols="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Mapa de calor</h1>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card flat outlined>
        <a-leaflet-marker-cluster :aspect-ratio="$vuetify.breakpoint.xsOnly ? 1 : 21/9"/>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import ATelemetryOnlineBusinesses from "@/components/Telemetry/TelemetryOnlineBusinesses";
  import ALeafletMarkerCluster from "@/components/ALeafletMarkerClusterHeat";

  export default {
    name: 'a-dashboard',
    components: {ALeafletMarkerCluster, ATelemetryOnlineBusinesses}
  };
</script>
