<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Estabelecimento</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn disabled icon @click="$router.push({ name: 'businesses_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="businesses">
      <v-col cols="12">
        <v-text-field v-model="businessesSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchBusinesses()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="businessesHeaders"
                    :items="businesses.data"
                    :page.sync="businesses.meta.current_page"
                    :items-per-page.sync="businesses.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.avatar="{ item }">
            <v-avatar rounded width="48" height="48">
              <v-img :src="item.avatar ? item.avatar.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>
            </v-avatar>
          </template>
          <template v-slot:item.name="{ item }">
            <a :href="`https://client.pointurbano.com/businesses/${item.id}`" target="_blank">{{ item.name }}</a>
          </template>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.has_active_subscription="{ item }">
            <v-simple-checkbox v-model="item.has_active_subscription" disabled/>
          </template>
          <template v-slot:item.free_tier="{ item }">
            <v-simple-checkbox v-model="item.free_tier" disabled/>
          </template>
          <template v-slot:item.blocked="{ item }">
            <v-simple-checkbox v-model="item.blocked" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'businesses_update', params: { business: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn disabled @click="destroyBusiness(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'businesses_subscriptions', params: { business: item.id } }" icon class="mr-0">
              <v-icon>mdi-credit-card</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'businesses_plans', params: { business: item.id } }" icon class="mr-0">
              <v-icon>mdi-brightness-percent</v-icon>
            </v-btn>
            <v-btn @click="loginAsBusiness(item, 'admin')" icon class="mr-0">
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn @click="loginAsBusiness(item, 'front')" icon class="mr-0">
              <v-icon>mdi-monitor</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="businesses.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-business-list',
    data: () => ({
      page: 1,
      businessesSearch: '',
      businessesHeaders: [
        { text: 'Avatar', align: 'center', width: 48, value: 'avatar' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Categoria', value: 'category.name' },
        { text: 'Ativo', value: 'active' },
        { text: 'Inscrição Ativa', value: 'has_active_subscription' },
        { text: 'Nível Gratuito', value: 'free_tier' },
        { text: 'Bloqueado', value: 'blocked' },
        { text: 'Ação', align: 'center', width: 248, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toBusinessesPage', {
          manager: this.manager.id,
          search: this.businessesSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchBusinesses();
    },
    methods: {
      fetchBusinesses() {
        this.$store.dispatch('fetchBusinesses', {
          manager: this.manager.id,
        });
      },
      searchBusinesses() {
        this.$store.dispatch('searchBusinesses', {
          manager: this.manager.id,
          search: this.businessesSearch
        });
      },
      destroyBusiness(business) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteBusiness', {
            manager: this.manager.id,
            business: business.id
          }).then(() => {
            this.fetchBusinesses();
          });
        }
      },
      loginAsBusiness(business, subdomain) {
        this.$store.dispatch('loginAsBusiness', {
          manager: this.manager.id,
          business: business.id
        }).then((response) => {
          let params = `name=${response.data.name}&email=${response.data.email}&access_token=${response.data.access_token}`;
          window.open(`https://${subdomain}.pointurbano.com/third_party_login?${params}`, '_blank');
        });
      },
    },
    computed: {
      businesses() {
        return this.$store.getters.getBusinesses;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
