import AFaqList from './index'
import AFaqForm from './form'

export default [
  {
    path: '/faqs/',
    component: AFaqList,
    name: 'faqs',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/faqs/create',
    component: AFaqForm,
    name: 'faqs_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/faqs/:faq/update',
    component: AFaqForm,
    name: 'faqs_update',
    meta: {
      requiresAuth: true
    }
  },
];
