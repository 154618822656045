<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Postagem</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Postagem</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.title" label="Título*" type="text"
                                :error-messages="formErrors.title"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="payload.resume" label="Resumo*" type="text"
                                :error-messages="formErrors.resume"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Conteúdo da postagem</h1>
                </v-col>
                <v-col cols="12">
                  <a-editor auto-grow outlined v-model="payload.content" type="text"
                            :error-messages="formErrors.content"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-switch inset v-model="payload.draft" label="Rascunho"
                            :error-messages="formErrors.draft"
                            hide-details="auto"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Imagem de capa</h1>
                </v-col>
                <v-col cols="12">
                  <a-banner-search v-model="payload.banner_id"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storePost()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updatePost()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import ABannerSearch from "../../components/ABannerSearch";
  import AEditor from "../../components/AEditor";

  export default {
    name: 'a-post-form',
    components: {AEditor, ABannerSearch},
    data: () => ({
      formErrors: {},
      payload: {
        title: '',
        resume: '',
        content: '',
        draft: true,
        banner_id: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchPost', {
          post: this.$route.params.post
        }).then((response) => {
          this.payload = {
            title: response.data.data.title,
            resume: response.data.data.resume,
            content: response.data.data.content,
            draft: response.data.data.draft,
            banner_id: response.data.data.banner ? response.data.data.banner.id : '',
          }
        });
      }
    },
    methods: {
      storePost() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createPost', {
          manager: this.manager.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updatePost() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updatePost', {
          manager: this.manager.id,
          post: this.$route.params.post,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'posts_create';
      },
      updating() {
       return this.$route.name === 'posts_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
