<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Plano</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'businesses_plans_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="plans">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchPlans()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="headers"
                    :items="plans.data"
                    :page.sync="plans.meta.current_page"
                    :items-per-page.sync="plans.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.interval="{ item }">
            {{ intervals(item.interval) }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}
          </template>
          <template v-slot:item.valid_until="{ item }">
            <span v-if="item.valid_until">
              {{ DateTime.fromSQL(item.valid_until).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'businesses_plans_update', params: { plan: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyPlan(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="plans.meta.last_page" :total-visible="7" @input="fetchPlans()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-plan-list',
    data: () => ({
      plans: null,
      page: 1,
      search: '',
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Ativo', value: 'active' },
        { text: 'Intervalo', value: 'interval' },
        { text: 'Número de Parcelas', value: 'iterations' },
        { text: 'Valor', value: 'amount' },
        { text: 'Período de Teste', value: 'trial_period_days' },
        { text: 'Valido Até', value: 'valid_until' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
      DateTime: DateTime,
    }),
    created() {
      this.fetchPlans();
    },
    methods: {
      async fetchPlans() {
        const plans = await this.$axios.get(`/managers/${this.manager.id}/businesses/${this.$route.params.business}/plans`, { params: { name: this.search, page: this.page } })
        this.plans = plans.data;
      },
      async destroyPlan(plan) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/managers/${this.manager.id}/businesses/${this.$route.params.business}/plans/${plan.id}`)
          await this.fetchPlans();
        }
      },
      intervals(interval) {
        switch (interval) {
          case 'MONTH':
            return 'Mensal';
          case 'YEAR':
            return 'Anual';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
