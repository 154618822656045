<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Categoria</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Categoria</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.order" label="Ordem*" type="number"
                                :error-messages="formErrors.order"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea auto-grow rows="1" v-model="payload.description" label="Descrição*"
                                :error-messages="formErrors.description"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Imagem da categoria</h1>
                </v-col>
                <v-col cols="12">
                  <a-banner-search v-model="payload.banner_id"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cor da categoria</h1>
                </v-col>
                <v-col cols="12">
                  <v-color-picker v-model="payload.color" dot-size="25"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeCategory()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateCategory()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import ABannerSearch from "@/components/ABannerSearch";
  export default {
    name: 'a-category-form',
    components: {ABannerSearch},
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        order: '',
        color: '',
        description: '',
        banner_id: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchCategory', {
          category: this.$route.params.category
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            order: response.data.data.order,
            color: response.data.data.color,
            description: response.data.data.description,
            banner_id: response.data.data.banner ? response.data.data.banner.id : '',
          }
        });
      }
    },
    methods: {
      storeCategory() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createCategory', {
          manager: this.manager.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateCategory() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateCategory', {
          manager: this.manager.id,
          category: this.$route.params.category,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'categories_create';
      },
      updating() {
       return this.$route.name === 'categories_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
