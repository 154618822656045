import ACategoryList from './index'
import ACategoryForm from './form'

export default [
  {
    path: '/categories/',
    component: ACategoryList,
    name: 'categories',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/categories/create',
    component: ACategoryForm,
    name: 'categories_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories/:category/update',
    component: ACategoryForm,
    name: 'categories_update',
    meta: {
      requiresAuth: true
    }
  },
];
