<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Cookie</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'cookies_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="cookies">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchCookies()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="headers"
                    :items="cookies.data"
                    :page.sync="cookies.meta.current_page"
                    :items-per-page.sync="cookies.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'cookies_update', params: { cookie: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyCookie(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="cookies.meta.last_page" :total-visible="7" @input="fetchCookies()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-cookie-list',
    data: () => ({
      cookies: null,
      page: 1,
      search: '',
      headers: [
        { text: 'Título', value: 'title' },
        { text: 'Versão', value: 'version' },
        { text: 'Revogado', value: 'revoked' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    created() {
      this.fetchCookies();
    },
    methods: {
      async fetchCookies() {
        const cookies = await this.$axios.get(`/cookies`, { params: { name: this.search, page: this.page } })
        this.cookies = cookies.data;
      },
      async destroyCookie(cookie) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/managers/${this.manager.id}/cookies/${cookie.id}`)
          await this.fetchCookies();
        }
      },
    },
    computed: {
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
