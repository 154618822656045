import APaymentMethodList from './index'
import APaymentMethodForm from './form'

export default [
  {
    path: '/payment_methods/',
    component: APaymentMethodList,
    name: 'payment_methods',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/payment_methods/create',
    component: APaymentMethodForm,
    name: 'payment_methods_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payment_methods/:paymentMethod/update',
    component: APaymentMethodForm,
    name: 'payment_methods_update',
    meta: {
      requiresAuth: true
    }
  },
];
