<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Suporte</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Suporte</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <template v-if="creating">
                  <v-col cols="12">
                    <v-text-field v-model="payload.name" label="Nome*" type="text"
                                  :error-messages="formErrors.name"
                                  hide-details="auto" :rules="[$rules.required]"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="payload.email" label="Email*" type="text"
                                  :error-messages="formErrors.email"
                                  hide-details="auto" :rules="[$rules.required]"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="payload.role" :items="selectRoleOptions" label="Função*"
                              :error-messages="formErrors.role"
                              hide-details="auto" :rules="[$rules.required]"/>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="payload.birth_date" label="Data de Nascimento*" type="date"
                                  :error-messages="formErrors.birth_date"
                                  hide-details="auto" :rules="[$rules.required]"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="payload.password" label="Senha*" type="password"
                                  :error-messages="formErrors.password"
                                  hide-details="auto" :rules="[$rules.required, $rules.password]"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="payload.password_confirmation" label="Confirmar Senha*" type="password"
                                  :error-messages="formErrors.password_confirmation"
                                  hide-details="auto" :rules="[$rules.required, $rules.password]"/>
                  </v-col>
                </template>
                <template v-if="updating">
                  <v-col cols="12">
                    <v-switch inset v-model="payload.blocked" label="Bloquear" hide-details="auto"/>
                  </v-col>
                  <v-col cols="12" v-if="payload.blocked">
                    <v-select v-model="payload.blocked_reason" :items="selectBlockedReasons" label="Motivo do bloqueio*"
                              :error-messages="formErrors.blocked_reason"
                              hide-details="auto" :rules="[$rules.required]"/>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeManager()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateManager()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-manager-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        email: '',
        role: 'SUPPORT',
        birth_date: '',
        blocked: '',
        blocked_reason: '',
        password: '',
        password_confirmation: '',
      },
      selectRoleOptions: [
        { text: 'Desenvolvedor', value: 'DEVELOPER' },
        { text: 'Suporte', value: 'SUPPORT' },
        { text: 'Financeiro', value: 'FINANCIAL' },
        { text: 'Comunicação', value: 'MEDIA' },
      ],
      selectBlockedReasons: [
        { text: 'Linguágem Inapropriada', value: 'INAPPROPRIATE_LANGUAGE' },
        { text: 'Conteúdo Falso ou Spam', value: 'FAKE_CONTENT' },
        { text: 'Fraude', value: 'SCAM' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchManager', {
          manager: this.$route.params.manager
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            email: response.data.data.email,
            role: response.data.data.role,
            birth_date: response.data.data.birth_date,
            blocked: response.data.data.blocked,
            blocked_reason: response.data.data.blocked_reason,
          }
        });
      }
    },
    methods: {
      storeManager() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createManager', {
          manager: this.manager.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateManager() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateManager', {
          manager: this.$route.params.manager,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'managers_create';
      },
      updating() {
       return this.$route.name === 'managers_update';
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
