<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Postagem</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'posts_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="posts">
      <v-col cols="12">
        <v-text-field v-model="postsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchPosts()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="postsHeaders"
                    :items="posts.data"
                    :page.sync="posts.meta.current_page"
                    :items-per-page.sync="posts.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.banner="{ item }">
            <v-avatar rounded width="48" height="48">
              <v-img :src="item.banner ? item.banner.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>
            </v-avatar>
          </template>
          <template v-slot:item.draft="{ item }">
            <v-simple-checkbox v-model="item.draft" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'posts_update', params: { post: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyPost(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="posts.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-post-list',
    data: () => ({
      page: 1,
      postsSearch: '',
      postsHeaders: [
        { text: 'Banner', align: 'center', width: 48, value: 'banner' },
        { text: 'Título', value: 'title' },
        { text: 'Autor', value: 'manager.name' },
        { text: 'Rascunho', value: 'draft' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toPostsPage', {
          search: this.postsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchPosts();
    },
    methods: {
      fetchPosts() {
        this.$store.dispatch('fetchPosts');
      },
      searchPosts() {
        this.$store.dispatch('searchPosts', {
          search: this.postsSearch
        });
      },
      destroyPost(post) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deletePost', {
            manager: this.manager.id,
            post: post.id
          }).then(() => {
            this.fetchPosts();
          });
        }
      },
    },
    computed: {
      posts() {
        return this.$store.getters.getPosts;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
