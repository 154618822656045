<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      POINTURBANO {{ appName }} V{{ appVersion }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-application',
  computed: {
    appName() {
      return this.$store.getters.getAppName;
    },
    appVersion() {
      return this.$store.getters.getAppVersion;
    },
  }
};
</script>