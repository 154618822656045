<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Spot</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'spots_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="spots">
      <v-col cols="12">
        <v-text-field v-model="spotsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchSpots()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="spotsHeaders"
                    :items="spots.data"
                    :page.sync="spots.meta.current_page"
                    :items-per-page.sync="spots.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.banner="{ item }">
            <v-avatar rounded width="48" height="48">
              <v-img :src="item.banner ? item.banner.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>
            </v-avatar>
          </template>
          <template v-slot:item.type="{ item }">
            {{ spotTypes(item.type) }}
          </template>
          <template v-slot:item.params="{ item }">
            {{ JSON.stringify(item.params) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'spots_update', params: { spot: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroySpot(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="spots.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-spot-list',
    data: () => ({
      page: 1,
      spotsSearch: '',
      spotsHeaders: [
        { text: 'Banner', align: 'center', width: 48, value: 'banner' },
        { text: 'Nome', value: 'name' },
        { text: 'Ordem', value: 'order' },
        { text: 'Tipo', value: 'type' },
        { text: 'Rarâmetros', value: 'params' },
        { text: 'Hora Início', value: 'time_start' },
        { text: 'Hora Fim', value: 'time_end' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toSpotsPage', {
          search: this.spotsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchSpots();
    },
    methods: {
      fetchSpots() {
        this.$store.dispatch('fetchSpots');
      },
      searchSpots() {
        this.$store.dispatch('searchSpots', {
          search: this.spotsSearch
        });
      },
      destroySpot(spot) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteSpot', {
            manager: this.manager.id,
            spot: spot.id
          }).then(() => {
            this.fetchSpots();
          });
        }
      },
      spotTypes(type){
        switch (type) {
          case 'BUSINESS':
            return 'Estabelecimentos';
          case 'ITEM':
            return 'Itens';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      spots() {
        return this.$store.getters.getSpots;
      },
      manager() {
        return this.$store.getters.getLoginAuthManager;
      },
    },
  };
</script>
