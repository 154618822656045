<template>
  <v-autocomplete v-bind="$attrs" v-model="stateSelected" :items="states" item-text="name" item-value="id" :loading="loading"/>
</template>

<script>
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-state-search',
    props: ['value'],
    data: () => ({
      statesSearch: '',
      stateSelected: null,
      states: [],
      loading: false,
    }),
    created() {
      this.loading = true;
      resource.get('/states', { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.states = response.data.data;
      });
      if (this.value) {
        this.stateSelected = this.value;
      }
    },
    watch: {
      value(value) {
        this.stateSelected = value;
      },
      stateSelected(value) {
        this.$emit('input', value);
      },
    },
    computed: {},
  }
</script>
