<template>
  <v-dialog v-bind="$attrs" v-model="dialog" scrollable width="100%" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined v-bind="attrs" v-on="on" :loading="loading">
        <v-icon left>mdi-image</v-icon> Escolher na galeria
      </v-btn>
    </template>
    <v-card flat outlined>
      <v-card-title>
        <v-row dense>
          <v-col class="grow">Galeria</v-col>
          <v-col class="shrink">
            <v-btn small icon color="primary" @click="fetchBanners">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-item-group v-model="bannerSelected">
          <v-row dense>
            <v-col cols="4" md="3" v-for="banner in banners" :key="`banner-${banner.id}`">
              <v-item v-slot="{ active, toggle }" :value="banner.id">
                <v-card flat outlined @click="toggle">
                  <v-img :src="banner.url" :aspect-ratio="1" contain>
                    <v-fade-transition>
                      <div v-if="active" class="d-flex justify-center align-center fill-height" style="background-color: rgba(0, 0, 0, .7)">
                        <v-icon dark class="text-xs-center" size="68">mdi-check-circle-outline</v-icon>
                      </div>
                    </v-fade-transition>
                  </v-img>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn depressed @click="dialog = false" color="primary">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import resource from "../resource";

export default {
  inheritAttrs: false,
  name: 'a-banner-search',
  props: ['value'],
  data: () => ({
    bannersSearch: '',
    bannerSelected: null,
    banners: [],
    loading: false,
    dialog: false,
  }),
  created() {
    this.fetchBanners()
  },
  watch: {
    value(value) {
      this.bannerSelected = value;
    },
    bannerSelected(value) {
      this.$emit('input', value);
    },
  },
  methods: {
    fetchBanners() {
      this.loading = true;
      resource.get(`managers/${this.manager.id}/banners`, { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.banners = response.data.data;
      });
      if (this.value) {
        this.bannerSelected = this.value;
      }
    }
  },
  computed: {
    manager() {
      return this.$store.getters.getLoginAuthManager;
    },
  },
}
</script>
