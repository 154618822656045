import resource from '../../resource';

const state = {
  categories: null,
};

const getters = {
  getCategories(state) {
    return state.categories;
  },
};

const mutations = {
  SET_CATEGORIES(state, data) {
    state.categories = data;
  },
};

const actions = {
  fetchCategories(context) {
    return resource.get(`/categories`)
      .then((response) => {
        context.commit('SET_CATEGORIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchCategory(context, { category }) {
    return resource.get(`/categories/${category}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchCategories(context, { search }) {
    return resource.get(`/categories`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_CATEGORIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toCategoriesPage(context, { search, page }) {
    return resource.get(`/categories`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_CATEGORIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createCategory(context, { manager, payload }) {
    return resource.post(`managers/${manager}/categories`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateCategory(context, { manager, category, payload }) {
    return resource.put(`managers/${manager}/categories/${category}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteCategory(context, { manager, category }) {
    return resource.delete(`managers/${manager}/categories/${category}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
